import { ITheme, mergeStyleSets, Stack, useTheme } from "@fluentui/react";
import classNames from "classnames";
import { ConfiguratorComponentProps } from "configuration";
import { equals, filter, isEmpty, isNil, map, path, reduce } from "ramda";
import { useContext, useEffect, useMemo, useState } from "react";

import { ContainerTO, Value } from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { getSelectedConnectorParameters } from "../../../../context/configurationUtils";
import { useConfigurationContext } from "../../../../context/useConfiguration";
import { ProductContext } from "../../../../context/useProducts";
import { useSettings } from "../../../../context/useSettings";
import { isHiddenContainer } from "../../components/confSectionUtils";
import { filterByProductCrossReferences } from "../../components/confTableUtils";
import { useConfTableParams } from "../../components/useConfTableParams";
import { ConnectorSectionHeading } from "./ConnectorSectionHeading";
import {
  calculateConnectorContentHeight,
  extractParameterNames,
  getPossibleValues,
  getSelectedContactCounterpartId,
  getSelectedContactId,
  hasContacts,
  toPossibleValueOptions,
} from "./connectorSectionUtils";

function sectionStyle(theme: ITheme, height: number) {
  return mergeStyleSets({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    connectorSection: {
      background: theme.palette.white,
      borderRadius: "6px",
      margin: "0",
      maxWidth: "calc(100vw - 1rem)",

      boxShadow: "rgba(33, 33, 33, 0.2) 0px 2px 8px 0px",
      boxSizing: "border-box",
      padding: 0,

      "&.open": {
        borderRadius: "6px",
      },
    },
    content: {
      maxHeight: `${height}px`,
      padding: "0 2em 1em 1em",
      overflowY: "auto",
    },
  });
}

type ParametersProps = {
  data: ContainerTO;
  isConnector: boolean;
  isConnectorCounterpart: boolean;
  isOpen: boolean;
  open: {
    active: string;
    last: string | undefined;
    numValues: number;
  };
  sectionStyled: any;
  setOpen: (containerName: string | undefined) => void;
  onMore: () => void;
};

function Parameters(
  props: Readonly<ConfiguratorComponentProps<ContainerTO> & ParametersProps>,
) {
  const {
    data,
    isConnector,
    isConnectorCounterpart,
    isOpen,
    open,
    sectionStyled,
    setOpen,
    onMore,
  } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <Stack className={sectionStyled.content}>
      {map(
        (container) =>
          ComponentFactory.instanceOf(container.viewPort!, {
            ...props,
            data: container,
            key: container.id,
            open,
            setOpen,
            onMore,
            isConnector,
            isConnectorCounterpart,
          }),
        data.parameters,
      )}
    </Stack>
  );
}

export function ConnectorSection({
  data,
  open,
  setOpen,
  onMore,
  ...props
}: ConfiguratorComponentProps<ContainerTO> & { onMore(): void }) {
  const theme = useTheme();
  const { settings } = useSettings();
  const {
    guiTO,
    article,
    productCrossReferences,
    actions: { setCrossReferencesCharacteristics },
  } = useConfigurationContext();
  const products = useContext(ProductContext);
  const containerTOs = filter(
    (c: ContainerTO) => !isHiddenContainer(c),
    data.children,
  );
  const [disabled, setDisabled] = useState<boolean>(false);
  const isCounterpart = equals(data.name, "Gegenstueck");
  const isConnector = equals(data.name, "cb-contact");
  const isConnectorCounterpart = equals(data.name, "Contact_Gegenstueck");
  const isOpen = data.parameters && equals(open?.active, data.name);
  const tableMaxHeight = useConfTableParams();

  const selectedItems = useMemo(
    () => getSelectedConnectorParameters(guiTO),
    [guiTO],
  );
  const sectionStyled = useMemo(
    () =>
      sectionStyle(
        theme,
        calculateConnectorContentHeight(
          settings,
          guiTO!,
          products,
          article,
          tableMaxHeight,
        ),
      ),
    [theme, guiTO, products, article, tableMaxHeight, settings],
  );

  async function determinePossibleValueOptions() {
    if (isConnector || isConnectorCounterpart) {
      const contacts = getPossibleValues(guiTO!);
      const parameters = extractParameterNames(data);

      if (!isNil(contacts) && !isEmpty(contacts)) {
        const filteredProducts = filter(
          filterByProductCrossReferences(productCrossReferences, data),
          await products.getProducts(
            map((value: Value) => value.value, contacts),
          ),
        );
        setCrossReferencesCharacteristics(
          reduce(toPossibleValueOptions(filteredProducts), {}, parameters),
        );
      }
    }
  }

  useEffect(() => {
    determinePossibleValueOptions();
  }, []);
  useEffect(() => {
    (async () => {
      const selectedIds = isConnectorCounterpart
        ? getSelectedContactCounterpartId(selectedItems)
        : getSelectedContactId(selectedItems);

      if (isEmpty(selectedIds) || (!isConnectorCounterpart && !isConnector)) {
        setDisabled(false);
        return;
      }
      const selectedProducts = await products.getProducts(selectedIds);
      setDisabled(hasContacts(selectedProducts));
    })();
  }, [guiTO, selectedItems, products, isConnector]);

  useEffect(() => {
    determinePossibleValueOptions();
  }, [guiTO, productCrossReferences]);

  if (isHiddenContainer(data)) {
    return null;
  }

  if (equals(data.name, "cb-productdetails") && data.parameters) {
    return (
      <Stack key={data.id}>
        {ComponentFactory.instanceOf("center", {
          ...props,
          data: data,
          key: "center",
          open,
          setOpen,
          onMore,
        })}
      </Stack>
    );
  }

  if (isCounterpart) {
    const activeParameter = path(["parameters", 0], data);
    return (
      activeParameter && (
        <Stack>
          {ComponentFactory.instanceOf("counterpartRadioButton", {
            ...props,
            data: activeParameter,
            key: activeParameter.id,
            open,
            setOpen,
            onMore,
          })}
        </Stack>
      )
    );
  }

  return (
    <div key={data.id}>
      <ConnectorSectionHeading
        data={data}
        disabled={disabled}
        isOpen={isOpen}
        setOpen={setOpen}
      />

      {!isEmpty(containerTOs) &&
        map(
          (container: ContainerTO) => (
            <div
              key={container.id}
              className={classNames({
                [sectionStyled.connectorSection]: !equals(
                  container.name,
                  "Gegenstueck",
                ),
                open: classNames({ open: !isNil(open.active) }),
              })}
            >
              <ConnectorSection
                key={container.id}
                {...{ ...props, open, setOpen, onMore, data: container }}
              />
            </div>
          ),
          containerTOs,
        )}

      <Parameters
        data={data}
        isOpen={isOpen}
        open={open}
        onMore={onMore}
        sectionStyled={sectionStyled}
        setOpen={setOpen}
        isConnector={isConnector}
        isConnectorCounterpart={isConnectorCounterpart}
        {...props}
      />
    </div>
  );
}
