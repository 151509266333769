import { mergeStyleSets } from "@fluentui/react";
import classNames from "classnames";
import { useMemo } from "react";

import { VisProperty } from "../../context/VisualizationProvider";
import { useVisualization } from "../../hooks/useVisualization";

function componentStyle(minWidth: string) {
  return mergeStyleSets({
    imageWrapper: {
      "&.hasMultipleImages": {
        maxWidth: minWidth,
        width: "min(100%, 15vw)",
      },
    },
    image: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      height: "100%",
      maxWidth: "100%",
      "&.flip": {
        transform: "scaleX(-1)",
      },
      "&.hasMultipleImages": {
        maxWidth: "auto",
        width: "min(100%, 15vw)",
      },
    },
  });
}

export function VisImage({
  uri,
  alt,
  visProperty,
}: Readonly<{
  uri: string;
  alt: string;
  visProperty: VisProperty;
}>) {
  const { visSettings } = useVisualization();
  const { imageWrapper, image } = useMemo(
    () => componentStyle(visProperty.vis_min_width),
    [visProperty],
  );
  const hasMultipleImages = visProperty.hasMultipleImages;

  if (visProperty.vis_background_image) {
    return (
      <div
        style={{
          width: `min(${visSettings.width}, 60vw)`,
          height: "100%",
        }}
      >
        <div
          style={{
            background: `url(${uri})${
              visProperty.vis_centered && " center center"
            } repeat-x`,
            flex: visProperty.vis_stretched ? 1 : 0,
          }}
          className={classNames(image, {
            flip: visProperty.vis_mirror,
          })}
        ></div>
      </div>
    );
  }
  return (
    <div className={classNames(imageWrapper, { hasMultipleImages })}>
      <img
        src={uri}
        className={classNames(image, {
          flip: visProperty.vis_mirror,
          hasMultipleImages,
        })}
        alt={alt}
      />
    </div>
  );
}
