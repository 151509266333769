import { mergeStyleSets, Stack } from "@fluentui/react";
import { ConfiguratorComponentProps } from "configuration";
import { and, filter, gt, isEmpty, length, map, not } from "ramda";
import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { ContainerTO } from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { ChevronDownIcon, ChevronUpIcon } from "../../../components/Icons";
import { Section } from "../../../components/Section";
import { SectionMobile } from "../../../components/SectionMobile";
import { mediaQuery } from "../../../theme";
import { isHiddenContainer } from "./confSectionUtils";

function sectionStyle() {
  return mergeStyleSets({
    entryWrapper: {
      height: "100%",
      alignItems: "stretch",
      display: "flex",
      gap: "2em",
      [mediaQuery.xl]: {
        flexFlow: "column nowrap",
        height: "auto",
        gap: "0",
      },
    },
    heading: {
      alignItems: "center",
      display: "flex",
      flexFlow: "row nowrap",
      gap: "10px",
      [mediaQuery.md]: {
        cursor: "pointer",
      },
    },
    headingIcon: {
      display: "flex",
      alignItems: "center",
      height: "24px",
      width: "12px",
      svg: {
        maxWidth: "100%",
      },
    },
    entry: {
      background: "#FFF",
      padding: "1em 0",
      flex: "1",
      [mediaQuery.md]: {
        padding: "0",
      },
    },
    chevron: {},
  });
}

export function ConfSection({
  data,
  ...props
}: Readonly<ConfiguratorComponentProps<ContainerTO>>) {
  const sectionStyled = useMemo(() => sectionStyle(), []);
  const mediaQueryMd = useMediaQuery({ query: "(max-width:768px)" });
  const [isOpen, setIsOpen] = useState<boolean>(not(mediaQueryMd));

  useEffect(() => {
    not(mediaQueryMd) && setIsOpen(true);
  }, [mediaQueryMd]);

  if (isHiddenContainer(data)) {
    return null;
  }

  const containerTOs = filter(
    (c: ContainerTO) => !isHiddenContainer(c),
    data.children,
  );

  function toggleExpanded() {
    setIsOpen((prev) => !mediaQueryMd || !prev);
  }

  return (
    <Stack key={data.id} style={{ overflow: "hidden" }}>
      {gt(length(data.parameters), 0) && (
        <Stack className={sectionStyled.heading} onClick={toggleExpanded}>
          {mediaQueryMd ? (
            <>
              {isOpen ? (
                <Stack className={sectionStyled.chevron}>
                  <ChevronUpIcon />
                </Stack>
              ) : (
                <Stack className={sectionStyled.chevron}>
                  <ChevronDownIcon />
                </Stack>
              )}
              <SectionMobile variant={"large"} label={data.translatedName} />
            </>
          ) : (
            <Section variant={"large"} label={data.translatedName} />
          )}
        </Stack>
      )}
      {!isEmpty(containerTOs) && (
        <div className={sectionStyled.entryWrapper}>
          {map(
            (container: ContainerTO) => (
              <div key={container.id} className={sectionStyled.entry}>
                <ConfSection
                  key={container.id}
                  {...{ ...props, data: container }}
                />
              </div>
            ),
            containerTOs,
          )}
        </div>
      )}
      {and(isOpen, data.parameters) &&
        map(
          (container) =>
            ComponentFactory.instanceOf(container.viewPort!, {
              ...props,
              data: container,
              key: container.id,
            }),
          data.parameters,
        )}
    </Stack>
  );
}
