import { mergeStyleSets, Stack } from "@fluentui/react";
import { useMemo } from "react";

import { mediaQuery } from "../../../theme";
import { Visualization } from "../../visualization/Visualization";
import { Configurator } from "../Configurator";
import { Bom } from "../bom/Bom";

function configurationStyle() {
  return mergeStyleSets({
    root: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gap: "0",
    },
    configurator: {
      flex: "1",
      padding: "0 0.5em",
      [mediaQuery.xl]: {
        padding: "0 0.5em",
      },
      [mediaQuery.lg]: {
        padding: "0 .5em",
      },
    },
    bom: {
      flex: "0 0 286px",
      padding: ".5em",
      [mediaQuery.md]: {
        padding: ".5em .5em 1em",
      },
    },
    wrapper: {
      display: "grid",
      gridTemplateColumns: "minmax(0, 1fr) 286px",
      [mediaQuery.xl]: {
        gridTemplateColumns: "1fr",
      },
    },
  });
}

export function TrolleyConfigurator() {
  const configurationStyled = useMemo(() => configurationStyle(), []);
  const { root, configurator, bom, wrapper } = configurationStyled;

  return (
    <div className={root}>
      <Stack style={{ maxWidth: "calc(100vw - 0.5em)" }}>
        <Visualization />
      </Stack>
      <div className={wrapper}>
        <div className={configurator}>
          <Configurator />
        </div>
        <div className={bom}>
          <Bom />
        </div>
      </div>
    </div>
  );
}
