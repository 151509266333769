import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IHoverCard,
  IPlainCardProps,
  ITheme,
  mergeStyleSets,
  Stack,
  Text,
  useTheme,
} from "@fluentui/react";
import classNames from "classnames";
import { equals, length, lt, not, path, pathOr } from "ramda";
import {
  PropsWithChildren,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { ContainerTO, ParameterTO } from "@encoway/c-services-js-client";

import {
  CheckmarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  InfoIcon,
} from "../../../../components/Icons";
import { Section } from "../../../../components/Section";
import { useConfigurationContext } from "../../../../context/useConfiguration";
import { ProductContext } from "../../../../context/useProducts";
import { getParameterStatus, getSectionStatus } from "./connectorSectionUtils";

const HOVERCARD_DISMISS_DELAY = 300;

function sectionStyle(theme: ITheme) {
  return mergeStyleSets({
    heading: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      cursor: "pointer",
      padding: "1em",
      span: {
        fontWeight: "300",
      },
      "&.disabled": {
        background: "#e0e0e0",
        span: {
          color: "#9e9e9e",
        },
      },
      "> span.ms-layer": {
        display: "none",
      },
    },
    headingOverlay: {
      cursor: "default",
      display: "grid",
      gridTemplateColumns: "auto 160px",
      gap: "1rem",
      gridTemplateAreas: ". .",
    },
    chevron: {},
    statusNotReady: {
      color: "#fd7a7a",
    },
    statusPartiallyReady: {
      color: theme.palette.themePrimary,
    },
    statusReady: {
      color: "#5eb07a",
    },
    statusCfgReady: {
      alignItems: "center",
      border: "1px solid #5eb07a",
      borderRadius: "100%",
      color: "#5eb07a",
      display: "flex",
      height: "22px",
      justifyContent: "center",
      width: "22px",
      svg: {
        height: "16px",
        width: "16px",
      },
    },
  });
}

type ConnectorSectionHeadingProps = {
  data: ContainerTO;
  isOpen: boolean;
  disabled: boolean;
  setOpen: (containerName: string | undefined) => void;
};

type ConnectorSectionHeadingContainerProps = {
  containerName: string;
  disabled: boolean;
  isOpen: boolean;
  setOpen: (containerName: string | undefined) => void;
};

type RequiredCountProps = {
  parameters: ParameterTO[];
  sectionName: string;
};

function DisabledHoverCard() {
  const theme = useTheme();
  const sectionStyled = useMemo(() => sectionStyle(theme), [theme]);
  const { t } = useTranslation();

  return (
    <Stack className={sectionStyled.headingOverlay}>
      <Stack style={{ color: theme.palette.themePrimary }}>
        <InfoIcon />
      </Stack>
      <Stack>
        <Text
          style={{
            color: "#212121",
            fontSize: 14,
            lineHeight: 1.2,
            fontWeight: "bold",
            paddingBlockEnd: ".4rem",
          }}
        >
          {t("t:common.noContactsTitle")}
        </Text>
        <Text style={{ color: "#212121", fontSize: 14, lineHeight: 1.2 }}>
          {t("t:common.noContactsMessage")}
        </Text>
      </Stack>
    </Stack>
  );
}

function closeHoverCard(hoverCard: RefObject<IHoverCard>) {
  if (hoverCard.current) {
    hoverCard.current.dismiss();
  }
}

function ConnectorSectionHeadingContainer(
  props: Readonly<PropsWithChildren<ConnectorSectionHeadingContainerProps>>,
) {
  const { children, containerName, disabled, isOpen, setOpen } = props;
  const theme = useTheme();
  const sectionStyled = useMemo(() => sectionStyle(theme), [theme]);
  const hoverCard = useRef<IHoverCard>(null);

  if (not(disabled)) {
    return (
      <Stack
        className={classNames(sectionStyled.heading, {
          openedHeading: isOpen,
        })}
        onClick={() => !disabled && setOpen(containerName)}
      >
        {children}
      </Stack>
    );
  }

  const plainCardProps: IPlainCardProps = {
    onRenderPlainCard: () => <DisabledHoverCard />,
    directionalHint: DirectionalHint.bottomLeftEdge,
    styles: {
      root: {
        background: `${theme.palette.white} !important`,
        position: "absolute",
        transform: "translate(180px, -20px) !important",
      },
    },
  };

  return (
    <HoverCard
      className={classNames(sectionStyled.heading, {
        openedHeading: isOpen,
        disabled,
      })}
      componentRef={hoverCard}
      onClick={() => null}
      onMouseLeave={() => closeHoverCard(hoverCard)}
      sticky={true}
      plainCardProps={plainCardProps}
      cardDismissDelay={HOVERCARD_DISMISS_DELAY}
      type={HoverCardType.plain}
    >
      {children}
    </HoverCard>
  );
}

function RequiredCount(props: Readonly<RequiredCountProps>) {
  const { parameters, sectionName } = props;
  const { getProducts } = useContext(ProductContext);
  const { guiTO } = useConfigurationContext();
  const theme = useTheme();
  const { statusNotReady, statusPartiallyReady, statusReady, statusCfgReady } =
    useMemo(() => sectionStyle(theme), [theme]);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const [ready, total] = getParameterStatus(parameters);
  const status = getSectionStatus(ready, total);
  const readyState = useMemo(
    () => path(["rootContainer", "readyState"], guiTO),
    [guiTO],
  );

  useEffect(() => {
    (async () => {
      const VISIBILITY_PATH = [
        sectionName,
        "characteristicValues",
        "mandatory_progress_visibility",
        "values",
        0,
        "value",
      ];
      const product = await getProducts(sectionName);
      const visibility = pathOr(0, VISIBILITY_PATH, product);
      setIsVisible(equals(visibility, 1));
    })();
  }, [sectionName]);

  if (!isVisible) {
    return null;
  }

  if (equals("READY", readyState)) {
    return (
      <Stack className={statusCfgReady}>
        <CheckmarkIcon />
      </Stack>
    );
  }
  return (
    <Stack
      className={classNames({
        [statusNotReady]: status.notReady,
        [statusPartiallyReady]: status.partiallyReady,
        [statusReady]: status.ready,
      })}
    >
      <Text variant={"large"}>
        ({`${ready}`}/{`${total}`})
      </Text>
    </Stack>
  );
}

export function ConnectorSectionHeading(
  props: Readonly<ConnectorSectionHeadingProps>,
) {
  const { data, isOpen } = props;
  const theme = useTheme();
  const sectionStyled = useMemo(() => sectionStyle(theme), [theme]);

  if (lt(length(data.parameters), 1)) {
    return null;
  }

  return (
    <ConnectorSectionHeadingContainer containerName={data.name} {...props}>
      <Stack horizontal style={{ alignItems: "center", gap: ".5rem" }}>
        <Section variant={"large"} label={data.translatedName} />
        <RequiredCount sectionName={data.name} parameters={data.parameters} />
      </Stack>
      {isOpen ? (
        <Stack className={sectionStyled.chevron}>
          <ChevronUpIcon />
        </Stack>
      ) : (
        <Stack className={sectionStyled.chevron}>
          <ChevronDownIcon />
        </Stack>
      )}
    </ConnectorSectionHeadingContainer>
  );
}
