import { mergeStyleSets, Stack } from "@fluentui/react";
import { ConfiguratorComponentProps } from "configuration";
import { map } from "ramda";
import { useMemo } from "react";

import { ContainerTO } from "@encoway/c-services-js-client";
import { ComponentFactory } from "@encoway/react-configurator";

import { useTab } from "../../../../context/useTab";
import { mediaQuery } from "../../../../theme";

function tabStyle() {
  return mergeStyleSets({
    root: {},
    viewport: {
      display: "grid",
      gridTemplateColumns: "295px minmax(0, 1fr)",
      gap: "0.5rem",
      padding: "0.5rem 0 0.5rem 0.5rem",
      width: "100%",
      [mediaQuery.lg]: {
        gridTemplateColumns: "minmax(0, 1fr)",
        padding: "0.5rem",
        gap: "1rem",
      },
      "> div": {
        display: "flex",
        flexFlow: "column",
        maxWidth: "100%",
        gap: "1em",
      },
    },
  });
}

export function ConnectorTab({
  data,
  ...props
}: Readonly<ConfiguratorComponentProps<ContainerTO>>) {
  const tabStyled = useMemo(() => tabStyle(), []);
  const { openTab, setOpen, onMore } = useTab();

  return (
    <Stack>
      <Stack.Item className={tabStyled.viewport}>
        {map(
          (container) =>
            ComponentFactory.instanceOf(container.viewPort!, {
              ...props,
              key: container.id,
              data: container,
              open: openTab,
              setOpen: setOpen,
              onMore,
            }),
          data.children,
        )}
      </Stack.Item>
    </Stack>
  );
}
